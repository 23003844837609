import { setCurrentlyOpenModal, useStore } from "@/data/store";
import { RoutesForSchedule, RouteStopModal } from "@/data/types";
import { routes } from "@/dummy";
import { getPermissionIfNecessary } from "@/notifications";
import { promiseWait } from "@/util";
import { IonButton, IonContent, IonIcon, IonItem, IonLabel, IonList, IonModal, IonNote, IonSpinner, useIonActionSheet, useIonAlert, useIonLoading, useIonToast } from "@ionic/react";
import { captureException } from "@sentry/react";
import { intlFormatDistance } from "date-fns";
import { notifications } from "ionicons/icons";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import "./RouteStop.css";

function convertDateString(dateString: string): Date {
	// Extract the timestamp using a regular expression
	const timestampMatch = /\/Date\((\d+)\)\//.exec(dateString);

	if (timestampMatch) {
		// Convert the extracted timestamp to a number
		const timestamp = parseInt(timestampMatch[1], 10);
		// Create and return a new Date object
		return new Date(timestamp);
	} else {
		throw new Error("Invalid date format");
	}
}

function notificationsSupported() {
	if (!('serviceWorker' in navigator)) {
		// Service Worker isn't supported on this browser, disable or hide UI.
		return false;
	}
	
	if (!('PushManager' in window)) {
		// Push isn't supported on this browser, disable or hide UI.
		return false;
	}
	return true;
}

export default function RouteStop({ routeStop }: { routeStop: RouteStopModal }) {
	const [present, dismissActionSheet] = useIonActionSheet();
	const [presentLoading, dismissLoading] = useIonLoading();
	const [presentAlert] = useIonAlert();
	const [presentToast] = useIonToast();
	const vehicles = useStore((state) => state.mapVehiclePoints);
	const routesForSchedule = useStore((state) => state.routesForSchedule);
	const allStopArrivalTimes = useStore((state) => state.stopArrivalTimes);
	const [currentDate, setCurrentDate] = useState(new Date()); // Save the current date to be able to trigger an update

	useEffect(() => {
		const timer = setInterval(() => {
			setCurrentDate(new Date());
		}, 1000);
		return () => {
			clearInterval(timer);
		}
	}, []);

	const stopsByAddressId = useMemo(() => {
		if (routesForSchedule === undefined) return new Map<number, RoutesForSchedule[0]['Stops']>();
		const routesByAddressId = new Map<number, RoutesForSchedule[0]['Stops']>();
		for (const route of routesForSchedule) {
			for (const stop of route.Stops) {
				if (!routesByAddressId.has(stop.AddressID)) {
					routesByAddressId.set(stop.AddressID, []);
				}
				routesByAddressId.get(stop.AddressID)?.push(stop);
			}
		}
		return routesByAddressId;
	}, [routesForSchedule]);
	
	const routeStops = useMemo(() => {
		const stops = stopsByAddressId.get(routeStop.addressId);
		if (!stops) return [];
		return stops.map((stop) => {
			const currentRoute = routesForSchedule?.find((route) => route.RouteID === stop.RouteID);
			const currentRouteStop = currentRoute?.Stops.find((routeStop) => routeStop.RouteStopID === stop.RouteStopID);
			const stopArrivalTimes = allStopArrivalTimes?.find((stopArrivalTime) => stopArrivalTime.RouteStopId === stop.RouteStopID);
			const arrivalTimes = stopArrivalTimes?.Times.map((arrivalTime) => {
				const estimatedTime = convertDateString(arrivalTime.EstimateTime);
				const arrivingVerySoon = estimatedTime.getTime() - currentDate.getTime() < 10 * 1000;
				const arrivedInPast = estimatedTime.getTime() - currentDate.getTime() < 0;
				const comparisonTime = (arrivingVerySoon || arrivedInPast) ? currentDate : estimatedTime;
				
				let relativeTime = intlFormatDistance(comparisonTime, currentDate);
				if (arrivingVerySoon || arrivedInPast) {
					relativeTime = "now";
				} else if (estimatedTime.getTime() - currentDate.getTime() < 60 * 1000) {
					let rounded = Math.round((estimatedTime.getTime() - currentDate.getTime()) / 15000) * 15;
					if (rounded === 60) {
						relativeTime = "in 1 minute";
					}
					relativeTime = `in ${rounded} seconds`;
				}

				return {
					arrivalTime,
					vehicle: vehicles?.find((vehicle) => vehicle.VehicleID === arrivalTime.VehicleId),
					estimatedTime: comparisonTime,
					relativeTime,
				}
			});

			return {
				currentRoute,
				currentRouteStop,
				arrivalTimes,
			}
		});
	}, [vehicles, currentDate, stopsByAddressId, allStopArrivalTimes]);

	const subscribeToNotifications = async (routeId: number | undefined | null) => {
		await dismissActionSheet();
		await presentLoading("Subscribing to notifications...");
		try {
			const [success, alertOptions] = await getPermissionIfNecessary();
			if (!success) {
				await dismissLoading();
				await presentAlert(alertOptions);
				return;
			}
			await Promise.all([
				fetch("https://jag-line-server.fly.dev/notifications/subscribe", {
					method: "POST",
					headers: {
						"Content-Type": "application/json"
					},
					body: JSON.stringify({
						clientId: localStorage.getItem('clientId'),
						addressId: routeStop.addressId,
						routeId,
					})
				}),
				promiseWait(250),
			])
			await presentToast({
				message: "Subscribed to notifications for this stop.",
				position: "top",
				duration: 3000,
			});
		} catch (error) {
			await presentToast({
				message: "Failed to subscribe to notifications.",
				position: "top",
				duration: 3000,
			});
			console.error(error);
			captureException(error);
		} finally {
			await dismissLoading();
		}
	}

	const promptNotification = () => {
		const routeNames = routeStops.map((routeStop) => routeStop.currentRoute?.Description.replace("Estimated Times", "").trim());
		present({
			header: 'Subscribe to notifications for this stop to be notified when a bus is arriving',
			subHeader: 'Select a route to subscribe to',
			buttons: [
				{
					text: "All Routes",
					handler: () => subscribeToNotifications(undefined),
				},
				...routeNames.map((routeName, index) => {
					return {
						text: routeName,
						handler: () => subscribeToNotifications(routeStops[index].currentRoute?.RouteID),
					}
				}),
				{
					text: 'Cancel',
					role: 'cancel',
					data: {
					  action: 'cancel',
					},
				  },
			]
		});
	}

	if (!routeStops || routeStops.length === 0) {
		return <div className="modal-spinner">
			<IonSpinner />
		</div>
	}

	return <IonContent>
		<div className="ion-padding" style={{paddingBottom: 0}}>
			{notificationsSupported() && <IonButton style={{float: 'right'}} onClick={promptNotification}>
				<IonIcon slot="icon-only" icon={notifications}></IonIcon>
			</IonButton>}
			<h1>{routeStops[0].currentRouteStop?.Description.trim()}</h1>
		</div>
		{routeStops.map((routeStop) => {
			const arrivalTimesCount = routeStop.arrivalTimes?.length || 0;
			return <Fragment key={routeStop.currentRoute?.RouteID}>
				<div className="ion-padding" style={{paddingTop: 0}}>
					<h2>{routeStop.currentRoute?.Description.replace("Estimated Times", "").trim()}</h2>
					{arrivalTimesCount === 0 && <p>No buses on this route are arriving at this stop</p>}
				</div>
				{arrivalTimesCount > 0 && <IonList>
					{routeStop.arrivalTimes?.map((arrivalTime) => {
						return <IonItem key={arrivalTime.arrivalTime.VehicleId}>
							<IonLabel>
								Bus {arrivalTime.vehicle?.Name}
							</IonLabel>
							<IonLabel slot="end" style={{textAlign: "right"}}>
								{arrivalTime.relativeTime}
								<p style={{textAlign: "right"}}>{arrivalTime.estimatedTime.toLocaleTimeString('en-US')}</p>
							</IonLabel>
						</IonItem>
					})}
				</IonList>}
			</Fragment>
		})}
	</IonContent>
}