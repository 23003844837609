import { setCurrentlyOpenModal, setRouteIdEnabled, useStore } from "@/data/store";
import { RouteSelectionModal, RouteStopModal } from "@/data/types";
import { IonContent, IonItem, IonLabel, IonList, IonModal, IonToggle } from "@ionic/react";
import { useMemo, useRef } from "react";

export default function RouteSelection({ routeSelection }: { routeSelection: RouteSelectionModal }) {
	const routesForSchedule = useStore((state) => state.routesForSchedule);
	const mapVehiclePoints = useStore((state) => state.mapVehiclePoints);
	const enabledRouteIds = useStore((state) => state.enabledRouteIds);

	const routes = useMemo(() => {
		return routesForSchedule?.map(route => {
			return {
				...route,
				Description: route.Description.replace("Estimated Times", "").trim(),
				vehicleCount: mapVehiclePoints?.filter(predicate => predicate.RouteID === route.RouteID).length,
			}
		});
	}, [routesForSchedule]);
	
	return <IonContent>
		<div className="ion-padding">
			<h1>
				JAG<b><i>LINE</i></b> Routes
			</h1>
		</div>
		<IonList>
			{routes?.map((route) => {
				return <IonItem key={route.RouteID}>
					<IonToggle
						checked={enabledRouteIds?.get(route.RouteID)}
						disabled={!enabledRouteIds}
						onIonChange={event => setRouteIdEnabled(route.RouteID, event.detail.checked)}
					>
						<IonLabel>
							{route.Description}
							<p>{route.InfoText} - { route.vehicleCount } bus{ route.vehicleCount === 1 ? "" : "es" } running</p>
						</IonLabel>
					</IonToggle>
				</IonItem>
			})}
		</IonList>
		<div className="ion-padding">
			For more route information, see <a target="_blank" href="https://parking.indianapolis.iu.edu/transportation/bus/bus.html#accordion-0-642351310">
				JAG<i><b>LINE</b></i> Shuttle Routes
			</a>.
		</div>
	</IonContent>
}