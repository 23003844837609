import { IonContent, IonHeader, IonItem, IonLabel, IonList, IonNote, IonPage, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonTitle, IonToggle, IonToolbar } from '@ionic/react';
import './Settings.css';
import { setGeolocateOnLoad, setMapStyle, useStore } from '@/data/store';

const Settings: React.FC = () => {
  const share = async () => navigator.share({
    url: 'https://jagline.app/',
  });
  const mapStyle = useStore(state => state.mapStyle);
  const geolocateOnLoad = useStore(state => state.geolocateOnLoad);

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Settings</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Settings</IonTitle>
          </IonToolbar>
        </IonHeader>
            <IonList>
              <IonItem>
                <IonLabel>
                  <IonToggle aria-label="Map Style" checked={geolocateOnLoad} onIonChange={(event) => setGeolocateOnLoad(!geolocateOnLoad)}>
                    Geolocate on launch
                  </IonToggle>
                  <p>Enabling this shows your location on app launch.</p>
                </IonLabel>
              </IonItem>
              <IonItem>
                <IonSelect aria-label="Map Style" interface="popover" label="Map style" value={mapStyle || "default"} onIonChange={(event) => setMapStyle(event.detail.value)}>
                  <IonSelectOption value="default">Default</IonSelectOption>
                  <IonSelectOption value="light">Light</IonSelectOption>
                  <IonSelectOption value="dark">Dark</IonSelectOption>
                </IonSelect>
              </IonItem>
              {'share' in navigator && <IonItem button onClick={share}>
                <IonLabel>Share JAG<b><i>LINE</i></b>++</IonLabel>
              </IonItem>}
              <IonItem routerLink='/settings/feedback'>
                <IonLabel>Send Feedback</IonLabel>
              </IonItem>
              <IonItem routerLink='/settings/debug'>
                <IonLabel>Debug Info</IonLabel>
              </IonItem>
            </IonList>


          <div className="ion-padding">
            <div className="ion-text-center">
              <div>
                A project made with
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" style={{fill: "#990000", verticalAlign: "middle"}} width={24} height={24}>
                  <path d="m11.645 20.91-.007-.003-.022-.012a15.247 15.247 0 0 1-.383-.218 25.18 25.18 0 0 1-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0 1 12 5.052 5.5 5.5 0 0 1 16.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 0 1-4.244 3.17 15.247 15.247 0 0 1-.383.219l-.022.012-.007.004-.003.001a.752.752 0 0 1-.704 0l-.003-.001Z" />
                </svg>
                by <a href="https://github.com/nealol">Nea</a> and <a href="https://github.com/catgirlinspace">Saige</a>.
              </div>
              <div>
                Route data from <a href="https://transloc.com/" target="_blank">TransLoc</a>.
              </div>
              <IonNote className="deploy-notes">
                <div style={{paddingTop: "0.25rem"}}>
                  Commit {__COMMIT_HASH__}
                </div>
                <div>
                  Host {window.location.hostname}
                </div>
              </IonNote>
            </div>
          </div>
      </IonContent>
    </IonPage>
  );
};

export default Settings;
